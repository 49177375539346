<template>
  <v-row>
    <v-col
      cols="12"
      v-if="
        user.role == 'ADMIN' &&
        item.statusBungaTabunganBulanIni.tagih == 1 &&
        item.statusBungaTabunganBulanIni.sudah_diproses == 0
      "
    >
      <v-alert dense text type="warning">
        <span style="color: black">Bunga tabungan bulan ini belum di proses. </span>
        <v-btn small @click="tambahBungaTabungan()" color="accent">Proses</v-btn></v-alert
      >
    </v-col>
    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Jumlah Anggota</v-card-title>
            <v-card-text class="pb-2 ps-2">
              <v-simple-table class="text-xl mb-4">
                <tr>
                  <td>Aktif</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.jumlah_anggota_aktif)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Pasif</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.jumlah_anggota_nonaktif)
                    }}</span>
                  </td>
                </tr>
              </v-simple-table>
              <router-link :to="{ name: 'master-anggota', params: {} }">
                <v-btn small v-if="user.role == 'ADMIN'" color="primary">Detail</v-btn>
              </router-link>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Pinjaman</v-card-title>
            <v-card-text class="py-2 px-0">
              <v-simple-table class="text-xl mb-4">
                <tr>
                  <td>Total</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalPinjaman)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Hari Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalPinjamanperHari)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bulan Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalPinjamanperBulan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Tahun Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalPinjamanThnIni)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Blm Lunas</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalPinjamanBlmLunas)
                    }}</span>
                  </td>
                </tr>
                <!-- <tr>
                  <td>BLm Lunas+Bunga</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalPinjamanSetoranMskPls_bunga)
                    }}</span>
                  </td>
                </tr> -->
              </v-simple-table>
              <router-link :to="{ name: 'pinjaman', params: {} }">
                <v-btn small v-if="user.role == 'ADMIN'" color="primary">Detail</v-btn>
              </router-link>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Angsuran</v-card-title>
            <v-card-text class="py-2 px-0">
              <v-simple-table class="text-xl mb-4">
                <tr>
                  <td>Belum Lunas</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalAngsuranBelumLunas)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bunga Bulan Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalBungaPerBulanSekarang)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bunga Tahun Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalBungaPerTahunSekarang)
                    }}</span>
                  </td>
                </tr>
              </v-simple-table>
              <!-- <router-link :to="{ name: 'pinjaman', params: {} }">
                <v-btn small v-if="user.role == 'ADMIN'" color="primary">Detail</v-btn>
              </router-link> -->
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Simpanan</v-card-title>
            <v-card-text class="py-2 px-0">
              <v-simple-table class="text-xl mb-4">
                <tr>
                  <td>Total</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalSimpananSemua)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Hari ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalSimpananperHari)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Bulan ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalSimpananperBulan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Tahun ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalSimpananperTahun)
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td>Keluar Hari ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalSimpananperKreditHari)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar Bulan ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalSimpananperKreditBulan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar Tahun ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalSimpananperKreditTahun)
                    }}</span>
                  </td>
                </tr>
              </v-simple-table>
              <router-link :to="{ name: 'daftar-simpanan', params: {} }">
                <v-btn small v-if="user.role == 'ADMIN'" color="primary">Detail</v-btn>
              </router-link>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Tabungan</v-card-title>
            <v-card-text class="py-2 px-0">
              <v-simple-table class="text-xl mb-4">
                <tr>
                  <td>Total</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalTabunganSemua)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Hari ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalTabunganperHari)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Bulan ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalTabunganperBulan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Tahun ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalTabunganperTahun)
                    }}</span>
                  </td>
                </tr>

                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td>Keluar Hari ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalTabunganKreditperHari)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar Bulan ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalTabunganKreditperBulan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar Tahun ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalTabunganKreditperTahun)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td>Jasa Bulan Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalJasaTabunganperBulan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Jasa Tahun ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalJasaTabunganperTahun)
                    }}</span>
                  </td>
                </tr>
              </v-simple-table>
              <router-link :to="{ name: 'daftar-tabungan', params: {} }">
                <v-btn small v-if="user.role == 'ADMIN'" color="primary">Detail</v-btn>
              </router-link>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Kas Masuk/Keluar</v-card-title>
            <v-card-text class="py-2 px-0">
              <v-simple-table class="text-xl mb-4">
                <tr>
                  <td>Total Masuk</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasMasukSemua)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Hari ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasMasukPerHari)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Bulan ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasMasukPerBln)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Masuk Tahun ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasMasukPerTahun)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td>Total Keluar</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasKeluarSemua)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar Hari ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasKeluarPerHari)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar Bulan ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasKeluarPerBln)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar Tahun ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      new Intl.NumberFormat(['id']).format(item.totalKasKeluarPerTahun)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">&nbsp;</td>
                </tr>
                <tr>
                  <td>Jumlah</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">
                      {{ new Intl.NumberFormat(['id']).format(item.totalKasMasukSemua - item.totalKasKeluarSemua) }}
                    </span>
                  </td>
                </tr>
              </v-simple-table>
              <router-link :to="{ name: 'jurnal-umum', params: {} }">
                <v-btn small v-if="user.role == 'ADMIN'" color="primary">Detail</v-btn>
              </router-link>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Arisan</v-card-title>
            <v-card-text class="py-2 px-0">
              <div class="text-center" v-if="Object.keys(arisan).length === 0">
                <v-btn color="primary" @click="getDataArisan()">
                  <v-icon title="Lihat Data"> {{ icons.mdiRefresh }} </v-icon>
                </v-btn>
              </div>

              <v-simple-table class="text-xl mb-4" v-else>
                <tr>
                  <th colspan="3" style="text-align: left">Arisan Harian</th>
                </tr>
                <tr>
                  <td>Masuk</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_harian_masuk)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_harian_keluar)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Pendapatan</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_harian_pendapatan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <th colspan="3" style="text-align: left">Arisan Mingguan</th>
                </tr>
                <tr>
                  <td>Masuk</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_mingguan_masuk)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_mingguan_keluar)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Pendapatan</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_mingguan_pendapatan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <th colspan="3" style="text-align: left">Arisan Bulanan</th>
                </tr>
                <tr>
                  <td>Masuk</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_bulanan_masuk)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Keluar</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_bulanan_keluar)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Pendapatan</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.arisan_bulanan_pendapatan)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <th colspan="3" style="text-align: left">Arisan Masuk Per Waktu</th>
                </tr>
                <tr>
                  <td>Hari Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.iuran_masuk_hari_ini)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Minggu Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.iuran_masuk_minggu_ini)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bulan Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.iuran_masuk_bulan_ini)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Tahun Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.iuran_masuk_tahun_ini)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <th colspan="3" style="text-align: left">Pendapatan Bunga Per Waktu</th>
                </tr>
                <tr>
                  <td>Hari Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.pendapatan_hari_ini)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Minggu Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.pendapatan_minggu_ini)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bulan Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.pendapatan_bulan_ini)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Tahun Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(arisan.pendapatan_tahun_ini)
                    }}</span>
                  </td>
                </tr>
              </v-simple-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Toko Online</v-card-title>
            <v-card-text class="py-2 px-0">
              <div class="text-center" v-if="Object.keys(tokoonline).length === 0">
                <v-btn color="primary" @click="getDataTokoOnline()">
                  <v-icon title="Lihat Data"> {{ icons.mdiRefresh }} </v-icon>
                </v-btn>
              </div>
              <v-simple-table class="text-xl mb-4" v-else>
                <tr>
                  <td>Total</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{ formatMoney(tokoonline.totalKredit) }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Hari Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(tokoonline.totalKreditHariIni)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bulan Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(tokoonline.totalKreditBulanIni)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Tahun Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(tokoonline.totalKreditTahunIni)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Blm Lunas</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(tokoonline.totalKreditBelumLunas)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bunga Bulan Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(tokoonline.totalBungaBulanIni)
                    }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Bunga Tahun Ini</td>
                  <td>=</td>
                  <td style="text-align: right">
                    <span class="font-weight-semibold primary--text">{{
                      formatMoney(tokoonline.totalBungaTahunIni)
                    }}</span>
                  </td>
                </tr>
                <tr></tr
              ></v-simple-table>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="12">
      <v-card class="greeting-card">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-card-title class="font-weight-black py-1">Kas Masuk/Keluar Per Tanggal</v-card-title>
            <v-card-text class="py-2 px-0">
              <v-data-table
                dense
                :headers="headers"
                :items="desserts"
                :loading="loading"
                hide-default-footer
                :page.sync="page"
                :items-per-page="table.itemsPerPage"
                class="elevation-1"
              >
                <template v-slot:item.debit="{ item }">
                  <div style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(parseFloat(item.debit)) }}
                  </div>
                </template>
                <template v-slot:item.kredit="{ item }">
                  <div style="text-align: right">
                    {{ new Intl.NumberFormat(['id']).format(parseFloat(item.kredit)) }}
                  </div>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" small @click="getKasPerTanggal"> Reset </v-btn>
                </template>
              </v-data-table>
              <div class="text-center px-4 py-4">
                <v-row>
                  <v-col cols="3">
                    <v-select
                      label="Jumlah Per Halaman"
                      :items="length_menus"
                      hide-details="auto"
                      outlined
                      dense
                      v-model="table.itemsPerPage"
                      @change="optionChange()"
                    ></v-select>
                  </v-col>
                  <v-col cols="9">
                    <v-pagination v-model="page" :length="table.pageCount" :total-visible="7"></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiRefresh } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  data() {
    return {
      icons: {
        mdiRefresh,
      },
      item: {
        jumlah_anggota_aktif: 0,
        jumlah_anggota_nonaktif: 0,

        // totalTabungan: 0,
        totalTabunganSemua: 0,
        totalTabunganperTahun: 0,
        totalTabunganperBulan: 0,
        totalTabunganperHari: 0,
        totalTabunganKreditperTahun: 0,
        totalTabunganKreditperBulan: 0,
        totalTabunganKreditperHari: 0,
        totalJasaTabunganperBulan: 0,
        totalJasaTabunganperTahun: 0,

        totalPinjaman: 0,
        totalPinjamanperBulan: 0,
        totalPinjamanperHari: 0,
        totalPinjamanBlmLunas: 0,

        // totalSimpanan: 0,
        totalSimpananSemua: 0,
        totalSimpananperTahun: 0,
        totalSimpananperBulan: 0,
        totalSimpananperHari: 0,
        totalSimpananperKreditTahun: 0,
        totalSimpananperKreditBulan: 0,
        totalSimpananperKreditHari: 0,

        totalAngsuranBelumLunas: 0,
        totalBungaPerBulanSekarang: 0,
        totalBungaPerTahunSekarang: 0,

        totalKasKeluarPerBln: 0,
        totalKasKeluarPerHari: 0,
        totalKasKeluarPerTahun: 0,
        totalKasMasukPerBln: 0,
        totalKasMasukPerHari: 0,
        totalKasMasukPerTahun: 0,
        totalKasMasukSemua: 0,
        totalKasKeluarSemua: 0,

        statusBungaTabunganBulanIni: {
          tagih: 0,
          sudah_diproses: 0,
        },
      },
      arisan: {},
      tokoonline: {},

      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'Tanggal', value: 'tanggal', sortable: false },
        { text: 'Debit', value: 'debit', sortable: false },
        { text: 'Kredit', value: 'kredit', sortable: false },
      ],
      desserts: [],
      loading: false,
      page: 1,
      table: {
        pageCount: 0,
        itemsPerPage: 10,
        search: '',
      },
      length_menus: [10, 25, 50, 100],
    }
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
    user() {
      return this.$store.getters.user
    },
  },
  watch: {
    page: {
      handler() {
        this.getKasPerTanggal()
      },
      deep: true,
    },
  },
  created() {
    this.getDataFromApi()
    this.getKasPerTanggal()
  },
  methods: {
    initialize() {},
    getDataFromApi() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Dashboard/getDashboard`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.item = Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataArisan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Dashboard/getDashboard_arisan`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.arisan = Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getDataTokoOnline() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Dashboard/getDashboard_tokoonline`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.tokoonline = Object.assign({}, response.data.data)
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    tambahBungaTabungan() {
      this.$store.dispatch('loading', true)
      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/Tabungan/addBungaTabungan`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            window.location.reload()
          } else {
            alert(response.data.message)
          }
          this.$store.dispatch('loading', false)
        })
        .catch(error => {
          alert(error)
          this.$store.dispatch('loading', false)
        })
    },
    getKasPerTanggal() {
      this.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.page)
      form.append('itemsPerPage', this.table.itemsPerPage)
      form.append('search', this.table.search)
      axios
        .post(`${apiRoot}/api/Dashboard/getDetailTableJurnal`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.table.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.loading = false
        })
        .catch(error => {
          alert(error)
          this.loading = false
        })
    },
    optionChange() {
      this.page = 1
      this.getKasPerTanggal()
    },
  },
}
</script>
